@tailwind base;
@tailwind components;
@tailwind utilities;

/* Alpine.js hides the content until the page is fully loaded */
[x-cloak] { display: none !important; }

/* HTML: <div class="loader"></div> from https://css-loaders.com/progress/ */
.loader {
    width: auto;
    aspect-ratio: 1;
    border-radius: 50%;
    -webkit-mask: linear-gradient(0deg,#000 55%,#0000 0) bottom/100% 18.18%;
    background:
            linear-gradient(theme('colors.primary.500') 0 0) bottom/100% 0% no-repeat
            #ddd;
    animation: l8 2s infinite steps(7);
}
@keyframes l8 {
    100% {background-size:100% 115%}
}
